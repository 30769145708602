import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {
  BaseFeatureModuleService,
  Belt,
  BeltService,
  DatatablePageService,
  HttpErrorHandlingService,
  PreviousRouteService,
  ServiceBeltService
} from '@app/core';
import {EquipmentServiceInterface} from '@app/core/model/belt/equpment-service.interface';
import {EquipmentInterface} from '@app/core/model/belt/equipment.interface';
import {BeltServiceCommentService} from '@app/core/service/belt/belt-service-comment.service';
import {MaintenanceComment} from '@app/core/model/belt/types';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalizeRouterService} from '@gilsdav/ngx-translate-router';

@Injectable()
export class BeltServiceEditService {
  private beltBS$ = new BehaviorSubject<Belt>(null);
  public belt$ = this.beltBS$.asObservable();

  private newCommentLoadingBS$ = new BehaviorSubject<boolean>(false);
  public newCommentLoading$ = this.newCommentLoadingBS$.asObservable();

  private saveInspectionLoadingBS$ = new BehaviorSubject<boolean>(false);
  public saveInspectionLoading$ = this.saveInspectionLoadingBS$.asObservable();

  private savePartsLoadingBS$ = new BehaviorSubject<boolean>(false);
  public savePartsLoading$ = this.savePartsLoadingBS$.asObservable();

  private saveCompleteLoadingBS$ = new BehaviorSubject<boolean>(false);
  public saveCompleteLoading$ = this.saveCompleteLoadingBS$.asObservable();

  public checkChangesS$ = new Subject<{ window: string, action: 'ask' | 'open', current: string }>();

  constructor(public dtPageSvc: DatatablePageService,
              private route: ActivatedRoute,
              @Inject(ServiceBeltService)
              private beltServiceService: BaseFeatureModuleService<EquipmentServiceInterface>,
              @Inject(BeltService)
              private beltService: BaseFeatureModuleService<EquipmentInterface>,
              private httpErrorHandlingSvc: HttpErrorHandlingService,
              private router: Router,
              private localize: LocalizeRouterService,
              private previousUrlSvc: PreviousRouteService,
              private beltServiceCommentService: BeltServiceCommentService) {
  }

  setBelt(data): void {
    this.beltBS$.next(data);
  }

  changeServiceDueDate(date) {
    const value = this.beltBS$.value;
    this.beltBS$.next({
      ...value,
      Service: {
        ...value.Service,
        DueDate: date
      }
    });
  }

  changeServiceAssignedTo(user: any) {
    const value = this.beltBS$.value;
    this.beltBS$.next({
      ...value,
      Service: {
        ...value.Service,
        AssignedTo: user
      }
    });
  }

  changeBarcode(barcode: string) {
    const value = this.beltBS$.value;
    this.beltBS$.next({
      ...value,
      Barcode: barcode
    });
  }

  changeInspections(inspections) {
    const value = this.beltBS$.value;
    this.beltBS$.next({
      ...value,
      Inspections: inspections
    });
  }

  changeParts(data) {
    const value = this.beltBS$.value;
    this.beltBS$.next({
      ...value,
      Parts: data.Parts,
      Service: {
        ...value.Service,
        Parts: data.Service.Parts
      }
    });
  }

  addNewCommentLocally(comment: MaintenanceComment) {
    const value = this.beltBS$.value;
    this.beltBS$.next({
      ...value,
      Service: {
        ...value.Service,
        Comments: [...value.Service.Comments, comment]
      }
    });
  }

  saveServiceChanges() {
    const value = this.beltBS$.value;
    const body: any = {
      Id: value.Service.Id,
      AssignedId: value.Service.AssignedTo ? value.Service.AssignedTo.Id : null,
      DueDate: value.Service.DueDate
    };

    this.beltServiceService.update(body)
      .subscribe(() => {
      });
  }

  saveBarcodeChanges() {
    const value = this.beltBS$.value;
    const body: any = {Id: value.Id, Barcode: value.Barcode};
    this.beltService.update(body, `/service`)
      .subscribe(
        () => {
        }
      );
  }

  saveInspectionChanges(inspections) {
    this.saveInspectionLoadingBS$.next(true);
    const value = this.beltBS$.value;
    const body: any = {Id: value.Id, Inspections: inspections};
    this.beltService.update(body, `/service?expand=Inspections&fields=Inspections.*`)
      .subscribe(
        (data) => {
          this.changeInspections(data.Inspections);
          this.saveInspectionLoadingBS$.next(false);
        }, () => this.saveInspectionLoadingBS$.next(false)
      );
  }

  savePartsChanges(changes) {
    this.savePartsLoadingBS$.next(true);
    const value = this.beltBS$.value;
    const body: any = {Id: value.Id, ...changes};
    this.beltService.update(body, '/service?expand=Parts,Parts.PartCategory,Service.Parts,Parts.PartCategory.PartType'
    )
      .subscribe(
        (data) => {
          this.changeParts(data);
          this.savePartsLoadingBS$.next(false);
        }, (err) => {
          this.savePartsLoadingBS$.next(false);
          this.httpErrorHandlingSvc.error(err);
        }
      );
  }

  saveComment(newComment: FormData) {
    this.newCommentLoadingBS$.next(true);
    this.beltServiceCommentService.create(newComment).subscribe((data: MaintenanceComment) => {
      this.addNewCommentLocally(data);
      this.newCommentLoadingBS$.next(false);
    }, () => this.newCommentLoadingBS$.next(false));
  }

  saveComplete() {
    this.saveCompleteLoadingBS$.next(true);
    const value = this.beltBS$.value;
    const body: any = {Id: value.Id};

    this.beltService.update(body, '/repair')
      .subscribe(() => {
          this.saveCompleteLoadingBS$.next(false);
          this.router.navigate([this.localize.translateRoute(this.previousUrlSvc.getPreviousUrl())],
            {queryParams: this.dtPageSvc.getQueryParam(), relativeTo: this.route});
        }, (err) => {
          this.saveCompleteLoadingBS$.next(false);
          this.httpErrorHandlingSvc.error(err);
        }
      );
  }
}
